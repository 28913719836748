<template>
  <div>
    <SideBar
      :navigation-items="navigation"
      :user-settings-route="userSettingsRoute"
      :handle-side-bar-open="handleSideBarOpen"
      :sidebar-open="sidebarOpen"
      :show-settings="true"
      @close-sidebar="closeSidebar"
    />
    <TopNav
      :sidebar-open="sidebarOpen"
      :user-settings-route="userSettingsRoute"
      :handle-side-bar-open="handleSideBarOpen"
      :show-settings="true"
    />

    <main class="py-10 lg:pl-72">
      <div class="px-4 sm:px-6 lg:px-8">
        <slot />
      </div>
    </main>
    <Toast />
  </div>
</template>

<script setup>
import {
  UsersIcon,
  HomeIcon,
  ListBulletIcon,
  ArrowPathIcon,
  ChatBubbleLeftIcon,
} from "@heroicons/vue/20/solid";
import Toast from "primevue/toast";
const user = useSupabaseUser();
const userSettingsRoute = `/user-settings/${user.value?.id}`;

const authStore = useAuthStore();
const { isAdmin, isAdvisor } = storeToRefs(authStore);
const sidebarOpen = ref(false);

const navigation = computed(() => {
  const navItems = [
    { name: "Dashboard", href: "/dashboard", icon: HomeIcon, current: true },
    { name: "Leads", href: "/leads", icon: ListBulletIcon, current: false },
    {
      name: "Consultation Requests",
      href: "/consultation-requests",
      icon: ChatBubbleLeftIcon,
      current: false,
    },
  ];

  if (isAdmin.value) {
    navItems.push({
      name: "CPA Users",
      href: "/users/cpa",
      icon: UsersIcon,
      current: false,
    });

    navItems.push({
      name: "Financial Advisor Users",
      href: "/users/financial-advisor",
      icon: UsersIcon,
      current: false,
    });
  }

  if (isAdvisor.value) {
    navItems.push({
      name: "My Subscriptions",
      href: `/user-settings/my-subscriptions/`,
      icon: ArrowPathIcon,
      current: false,
    });
  }

  return navItems;
});

const handleSideBarOpen = () => {
  sidebarOpen.value = !sidebarOpen.value;
};

const closeSidebar = () => {
  sidebarOpen.value = false;
};
</script>
